import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ToastService } from 'src/app/core/services/toast.service';
import { AuthService } from 'src/app/auth/auth.service';
import { SharedService } from '../../shared.service';
import { UserConfigurationService } from 'src/app/core/services/user-configuration.service';
import { Message, MessageResponse, Platform, RecipientResponse, Status, User } from '../../interfaces/order-messages';
import { ActionPermissions } from '../../constants/permissions';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { message } from '../../constants/alerts_messages';
import { parseJwt } from '../../helpers/common';
import { OrderStatuses } from '../../enums';
import { trigger, transition, style, animate } from '@angular/animations';
import { generalErrorMessage } from '../../errors/error-messages';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class ChatComponent implements OnInit, AfterViewInit {
  @ViewChild('target') public myScrollContainer: ElementRef;
  permission = ActionPermissions;

  @Input() orderId: string;
  @Input() isPreview: boolean;
  @Input() unAssigned: boolean;
  @Input() searchTerm: string;
  @Input() clientName: string | null;
  @Input() clientImage: string;
  @Input() reviewer: any;
  @Output() searchTermChange = new EventEmitter<string>();

  showChatParticipants = false;
  showMessageBox = false;
  displayMessage: string;
  messagesData: Message[] = [];
  sendMessageForm: FormGroup;
  selectedRecipient: User;
  recipientList: User[] = [];
  currentUserId: string;
  token: string;

  sourcePlatformId: string;
  syncPlatformId: string;

  sendMessageStatusId: string;
  readMessageStatusId: string;

  orderStatuses = OrderStatuses;

  constructor(
    private sharedService: SharedService,
    public userConfig: UserConfigurationService,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private toastr: ToastService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.sendMessageForm = new FormGroup({
      recipient: new FormControl('', [Validators.required]),
      comment: new FormControl('', [Validators.required]),
      sendViaEmail: new FormControl(false),
    });

    this.authService.getCurrentUser().then((user) => {
      this.currentUserId = user.uid;
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.token = params['token'];
    });
  }

  public toggleChatParticipantsIcons(): void {
    this.showChatParticipants = !this.showChatParticipants;
  }
  public toggleMessageBox(): void {
    this.showMessageBox = !this.showMessageBox;
  }

  highlightSearchTerm(content: string, searchTerm: string): SafeHtml {
    if (!searchTerm) {
      return content;
    }

    const searchRegex = new RegExp(searchTerm, 'gi');
    const highlightedContent = content.replace(searchRegex, (match) => `<strong>${match}</strong>`);
    // NOSONAR: bypassing Angular's html sanitization
    return this.sanitizer.bypassSecurityTrustHtml(highlightedContent);
  }

  searchMessagesByTerm(term: string): void {
    this.searchTerm = term;
    this.searchTermChange.emit(this.searchTerm);
    this.getOrderMessages(this.orderId, this.searchTerm);
  }

  getOrderMessages(orderId: string, searchTerm = ''): void {
    this.sharedService.getOrderMessages(orderId, searchTerm, this.token).subscribe(
      (messagesResponseData: MessageResponse) => {
        this.messagesData = messagesResponseData.data;
        searchTerm ? this.displayMessage = message.NoResultsFound : this.displayMessage = message.startConversation;
        setTimeout(() => {
          this.scrollToElement();
        }, 0);
      }
    );
  }

  getMessagePlatform(): void {
    this.sharedService.getMessagePlatform(this.token).subscribe(
      (response: Platform[]) => {
        for (let index = 0; index < response.length; index++) {
          if (response[index].platform_name === 'APPRAISAL_SCOPE') {
            this.syncPlatformId = response[index].id
          } else if (response[index].platform_name === 'VONNIQC') {
            this.sourcePlatformId = response[index].id
          }
        }
      });
  }

  getMessageStatus(): void {
    this.sharedService.getMessageStatus(this.token).subscribe(
      (response: Status[]) => {
        for (let index = 0; index < response.length; index++) {
          if (response[index].status_name === 'SENT') {
            this.sendMessageStatusId = response[index].id
          } else if (response[index].status_name === 'READ') {
            this.readMessageStatusId = response[index].id
          }
        }
        this.updateOrderMessagestatus();
      });
  }

  getMessageRecipients(): void {
    this.sharedService.getMessageRecipients(this.orderId, this.token).subscribe(
      (response: RecipientResponse) => {
        if (response) {
          this.recipientList = response.recipients;
        }
      });
  }

  sendMessage(): void {
    let external_id = '';
    if (this.token) {
      const appraiser = parseJwt(this.token);
      if(appraiser && appraiser?.data && appraiser?.data?.appraiser?.external_id) {
        external_id = appraiser.data.appraiser.external_id
      }
    }
    const receiver = this.sendMessageForm?.get('recipient')?.value
    const data = {
      order_id: this.orderId,
      sender_id: this.token ? external_id : this.currentUserId,
      receiver_id: receiver?.id,
      content: this.sendMessageForm?.get('comment')?.value,
      send_via_email: this.sendMessageForm?.get('sendViaEmail')?.value,
      source_platform_id: this.sourcePlatformId,
      sync_platform_id: this.syncPlatformId,
      status_id: this.sendMessageStatusId,
    };
    this.sharedService.sendOrderMessages(data, this.token).subscribe(
      (response: Message) => {
        if (response) {
          this.getOrderMessages(this.orderId);
          this.sendMessageForm.reset();
          this.sendMessageForm.get('sendViaEmail')?.setValue(false);
        }
      },
      (error) => {
        this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error?.error?.detail :  generalErrorMessage);
      },
    );
  }

  openDeleteMessageConfirmationModal(messageId: string) {
    if (!this.unAssigned) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        panelClass: ['order-complete-modal'],
        maxWidth: '100%',
        disableClose: true,
        data: {
          heading: 'Delete Message',
          contentData: message.DeleteMessageConfirmation,
          messageId: messageId
        }
      });
      dialogRef.afterClosed().subscribe(() => {
          this.getOrderMessages(this.orderId);
      });
    }
  }

  updateOrderMessagestatus(): void {
    this.sharedService.getOrderMessages(this.orderId, '', this.token).subscribe(
      (messagesResponseData: MessageResponse) => {
        const receivedMessages = messagesResponseData.data.filter((message: Message) =>
        message.sender.external_id !== this.currentUserId && message.status.status_name !== 'READ');
        if (receivedMessages.length > 0) {
          const payload = {
            status_id: this.readMessageStatusId
          }
          receivedMessages.forEach((message: Message) => {
            this.sharedService.updateMessageStatus(message.id, payload, this.token).subscribe(
              () => {
                //
              },
              (error) => {
                this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error?.error?.detail :  generalErrorMessage);
              },
            );
          });
          this.getOrderMessages(this.orderId);
        } else {
          this.getOrderMessages(this.orderId);
        }
      },
      (error) => {
        this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error?.error?.detail :  generalErrorMessage);
      },
    );
  }

  removeSpaces(control: any): void {
    const newValue = control?.value.trim();
    control?.setValue(newValue);
  }

  ngAfterViewInit() {
    this.route.params.subscribe(params => {
      this.token = params['token'];
      this.getMessageStatus();
      this.getMessagePlatform();
      this.getMessageRecipients();
    })
  }

  scrollToElement(): void {
    if (this.myScrollContainer && this.myScrollContainer.nativeElement) {
      this.myScrollContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }

  participantClickToSendMsg(user: any) {
    if (!this.unAssigned) {
      this.showChatParticipants = false;
      this.showMessageBox = true;
      if (this.recipientList && this.recipientList.length > 0) {
        const selectUser = this.recipientList.find((recipient: any) => recipient.id === user.id);
        if (selectUser) {
          this.selectedRecipient = selectUser;
          this.sendMessageForm.get('recipient')?.setValue(this.selectedRecipient);
        }
      }
    }
  }
}
