import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { parseISO } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(utcDate: string | null, formatStr = 'medium'): string {
    if (utcDate === null) {
      return '';
    }

    const parsedDate = parseISO(utcDate);
    const utcDateObj = fromZonedTime(parsedDate, 'UTC');
    return this.datePipe.transform(utcDateObj, formatStr) || '';
  }
}
