export const FINDINGS_SOURCE = ['MF', 'ACI', 'USPS', 'RW', 'Questions', 'NAN_STANDARD', 'GAAR', 'EAD', 'UCDP', 'ROOM CLASSIFICATION', 'OBJECT CLASSIFICATION', 'UNSUPPORTED PRODUCER'];

export const FINDINGS_STATUSES = ['Approved', 'Rejected', 'Not Applicable'];

export const ORDER_STATUSES = [
  "Processing",
  "Pending Reviewer",
  "Pending Escalated Review",
  "Revision Requested",
  "In Progress",
  "Pending Review",
  "Completed",
  "Archived"
]

export const OPENED_STATUSES = [
  "Processing",
  "Pending Reviewer",
  "Pending Escalated Review",
  "In Progress",
  "Pending Review"
]

export const COMPLETED_STATUSES = [
  "Completed",
  "Archived"
]

export const PENDING_REVIEW = ["Pending Review"];
export const REVISION_SENT = ["Revision Requested"];

export const BACKGROUND_COLORS = ['#FF5733', '#33FF57', '#3357FF', '#FF33A8', '#33FFF1', '#A833FF'];
