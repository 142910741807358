import { Directive, Input, OnInit, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

@Directive({
  selector: '[realTimer]'
})
export class RealTimerDirective implements OnInit, OnDestroy {
  @Input('realTimer') startTime: string; // Datetime string for each row
  @Input() format = 'DHMS';

  private intervalId: any;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.updateTimeDifference();

    // Update time difference every second
    this.intervalId = setInterval(() => {
      this.updateTimeDifference();
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  updateTimeDifference(): void {
    const startDate = fromZonedTime(this.startTime, 'UTC'); // Ensure start time is treated as UTC
    const currentDate = new Date(); // Current local time in UTC (implicitly in local)

    const diffDays = differenceInDays(currentDate, startDate);
    const totalHours = differenceInHours(currentDate, startDate);
    const diffHours = totalHours - (diffDays * 24); // Subtract days' hours to get remaining hours
    const diffMinutes = differenceInMinutes(currentDate, startDate) % 60; // Minutes after hours
    const diffSeconds = differenceInSeconds(currentDate, startDate) % 60; // Seconds after minutes

    let displayString = '';

    // Append days if included in format and difference is more than 0
    if (this.format.includes('D') && diffDays > 0) {
      displayString += `${diffDays} D : `;
    }

    // Handle hours, including conversion of days to hours for HMS format
    if (this.format.includes('H') && diffHours >= 0) {
      if (this.format === 'HMS') {
        const totalHoursWithDays = (diffDays * 24) + diffHours; // Convert days to hours and add hours
        displayString += `${totalHoursWithDays} H : `;
      } else {
        displayString += `${diffHours} H : `;
      }
    }

    if (this.format.includes('M')) {
      displayString += `${diffMinutes} M : `;
    }

    if (this.format.includes('S')) {
      displayString += `${diffSeconds} S`;
    }

    this.renderer.setProperty(this.el.nativeElement, 'innerText', displayString);
  }

}

