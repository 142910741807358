import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appLazyLoadImage]'
})
export class LazyLoadImageDirective implements OnInit {
  @Input() appLazyLoadImage!: string;
  @Input() loadImage!: (key: string) => void;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    const imgElement: HTMLImageElement = this.el.nativeElement;

    imgElement.src = '../../../assets/images/placholderAvatar.png';

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadImage(this.appLazyLoadImage);
          observer.unobserve(imgElement);
        }
      });
    });

    observer.observe(imgElement);
  }
}
