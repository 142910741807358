import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/core/services/toast.service';
import { message } from 'src/app/shared/constants/alerts_messages';
import { RuleEngineList, RuleEngineListSingular } from 'src/app/shared/enums';
import { RulesService } from '../rules.service';
import { generalErrorMessage } from 'src/app/shared/errors/error-messages';

@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: ['./create-question.component.scss']
})
export class CreateQuestionComponent {
  ruleEngine = RuleEngineList;
  addQuestion: FormGroup;
  submitted = false;
  clientId: any;
  isEditMode = false;

  constructor(private toastr: ToastService,
    private rulesService: RulesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>) {
    this.addQuestion = new FormGroup({
      question: new FormControl('', [Validators.required])
    });
    this.clientId = data.clientId;
    if (data?.data?.id) {
      this.addQuestion.get('question')?.setValue(data.data.question);
      this.isEditMode = true;
    }
  }

  onAddQuestion(formValue: any): void {
    this.submitted = true;
    if (!this.addQuestion.valid) {
      return;
    }
    const param: FormData = new FormData();
    if (this.data.data) {
      formValue.rule_id = this.data.data.id
      param.append('question', formValue.question);
      this.updateQuestion(param, formValue.rule_id);
    } else {
      param.append('rule', JSON.stringify(formValue));
      this.postQuestion(param);
      this.addQuestion.reset();
    }
  }


  postQuestion(param: any) {
    this.rulesService.createQuestion(param, this.clientId).then((response) => {
      this.toastr.success(`${RuleEngineListSingular.QUESTION} ${message.ruleCreated}`);
      this.dialogRef.close(true);
    }, error => {
      this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error?.error?.detail : generalErrorMessage);
    })
  }

  updateQuestion(param: any, rule_id: string) {
    this.rulesService.updateQuestion(param, this.clientId, rule_id).then((response) => {
      this.toastr.success(`${RuleEngineListSingular.QUESTION} ${message.ruleUpdated}`);
      this.dialogRef.close(true);
    }, error => {
      this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error?.error?.detail : generalErrorMessage);
    })
  }


  removeSpaces(control: any): void {
    const newValue = control.value.trim();
    control.setValue(newValue);
  }
}
