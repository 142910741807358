<div class="card">
  <div class="card-header" [ngClass]="{'preview': isPreview}">
    <div class="card-title" [title]="heading">{{heading}}</div>
    <div class="auto-add">
      <mat-checkbox class="example-margin" [formControl]="autoAddNewControl" *ngIf="!isPreview">Auto add new</mat-checkbox>
      <div class="auto-add__review" *ngIf="isPreview && autoAddNewControl.value"><app-svg [svgPath]="'../../../../assets/images/check-circle.svg'"></app-svg><span>Auto add new</span></div>
    </div>
  </div>
  <div class="card-body" [ngClass]="{'activeFilter': showFilter}" [ngClass]="{'preview': isPreview}">
    <div class="search-box" *ngIf="!showFilter">
      <mat-form-field class="example-full-width" subscriptSizing="dynamic">
        <input type="tel" matInput placeholder="Search list"  [(ngModel)]="searchTerm" (keyup.enter)="search()">
      </mat-form-field>
      <div class="search-icon">
        <mat-icon fontSet="material-icons-outlined" data-test-id="search-btn" (click)="search()">search</mat-icon>
      </div>
    </div>
    <div class="list-wrapper">
      <div class="list-header" [ngClass]="{'activeFilter': showFilter, 'preview': isPreview}">
        <div class="full-selection" [ngClass]="{'activeFilter': showFilter}">
          <div class="" *ngIf="showFilter">
            <h1 class="headline">Filters</h1>
          </div>
          <mat-checkbox class="example-margin" [checked]="isAllSelected"
          [indeterminate]="isAllSelected && unSelectIds.length > 0" *ngIf="!showFilter && !isPreview && searchTerm === ''"
          (change)="toggleSelectAll()">Select All</mat-checkbox>
        </div>
        <div class="filter-main" [ngClass]="{'activeFilter': showFilter}">
          <h4 *ngIf="showFilter" (click)="applyFilters(); toggleFilter();">Apply Filters</h4>
          <div class="close-box" *ngIf="showFilter" (click)="selectedFilters = []; toggleFilter(); applyFilters()"><app-svg [svgPath]="'../../../../assets/images/x-small.svg'"></app-svg></div>
          <div class="icon-box sorting-wrapper" *ngIf="!showFilter" (click)="sortData()">
            <app-svg [ngClass]="{'active': sortedIn === 'desc' && sortedOn !== 'created_on'}" [svgPath]="'../../../../assets/images/Up.svg'"></app-svg>
            <app-svg [ngClass]="{'active': sortedIn === 'asc' && sortedOn !== 'created_on'}" [svgPath]="'../../../../assets/images/Down.svg'"></app-svg>
          </div>
          <div class="icon-box" [ngClass]="{'fillter-wrapper': selectedFilters.length}" *ngIf="!showFilter && filterBy?.length !== 0" (click)="toggleFilter()"><app-svg [svgPath]="'../../../../assets/images/filter-1.svg'"></app-svg></div>
        </div>
      </div>
      <div class="list-body" [ngClass]="{'activeFilter': showFilter, 'preview': isPreview}"  (scroll)="scroll($event)">
        <ul class="list-body__list-wrapper" *ngIf="showFilter" >
          <li class="item" *ngFor="let filter of filterBy">
            <div class="items-checkbox">
              <mat-checkbox class="example-margin" [checked]="selectedFilters.includes(filter.key)"
              (change)="toggleFiltersSelection(filter)"></mat-checkbox>
            </div>
            <div class="items">
              <div class="items__wrapper">
                <div class="label">{{filter.name}}</div>
              </div>
            </div>
          </li>
        </ul>
        <ul class="list-body__list-wrapper" *ngIf="!showFilter">
          <li class="item" *ngFor="let item of dataSource">
            <div class="items-checkbox" *ngIf="!isPreview">
              <mat-checkbox class="example-margin" [checked]="selectedIds.includes(item.id) || (isAllSelected && !unSelectIds.includes(item.id))"
              (change)="toggleSelection(item)"></mat-checkbox>
            </div>
            <div class="items">
              <div class="items__wrapper">
                <div class="indicator active" *ngIf="heading === 'Clients' && item.status"></div>
                <div class="indicator deactive" *ngIf="heading === 'Clients'&& !item.status"></div>
                <div class="order-flag" *ngIf="heading === 'Order Flags'" [style.color]= "'#' + item.color"><app-svg [svgPath]="'../../../../assets/images/flag.svg'"></app-svg></div>
                <div class="label" [title]="item.name">{{item.name}}</div>
              </div>
              <div *ngIf="heading === 'Nan Standard Rules'" class="info-box" (click)="getNANStdDetail(item)"><app-svg [svgPath]="'../../../../assets/images/info.svg'"></app-svg></div>
            </div>

          </li>
          <div *ngIf="!dataSource.length">
            <p class="text-center">{{noDataMessage}}</p>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>
