import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RuleEngineList, RuleEngineListSingular } from '../../enums/checkList';
import { SharedService } from '../../shared.service';
import { config } from '../../constants/config';
import { TableActions, TableColumnTypes } from '../../enums/table';
import { ToastService } from 'src/app/core/services/toast.service';
import { message } from '../../constants/alerts_messages';
import { MatDialog } from '@angular/material/dialog';
import { CreateNanStdRulesComponent } from 'src/app/rules/create-nan-std-rules/create-nan-std-rules.component';
import { CreateRestrictedWordsComponent } from 'src/app/rules/create-restricted-words/create-restricted-words.component';
import { CreateQuestionComponent } from 'src/app/rules/create-question/create-question.component';
import { Subscription } from 'rxjs';
import { EventEmitterService } from 'src/app/core/services/event-emitter.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { CreateManualFindingGroupComponent } from 'src/app/rules/create-manual-finding-group/create-manual-finding-group.component';
import { CreateGaarRulesComponent } from 'src/app/rules/create-gaar-rules/create-gaar-rules.component';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { UserConfigurationService } from 'src/app/core/services/user-configuration.service';
import { permission } from '../../constants/permissions';

@Component({
  selector: 'app-rules-configurations',
  templateUrl: './rules-configurations.component.html',
  styleUrls: ['./rules-configurations.component.scss'],
})
export class RulesConfigurationsComponent {
  dataSource: any;
  columnConfiguration: any;
  @Input() ruleName: string;
  @Input() clientChecklist: any;
  @Input() selectedRule: any;
  @Input() clientId: any;
  @Output() selectedChecklistType = new EventEmitter<any>();
  @Output() getLoadedRules = new EventEmitter<any>();

  ruleEngine = RuleEngineList;
  statusCount: any;
  filters = {
    page_size: config.listPageSize,
    page: 1,
    descending: true,
    sort_field: 'created_on',
    search_term: '',
    status: 'all',
    count: false,
  };
  totalRecords = 0;
  searchTerm = '';
  pageInfo = {
    pageIndex: 0,
    pageSize: config.listPageSize,
    maxPagesToShow: config.maxPagesToShow,
    pageSizeOptions: config.pageSizeOptions,
  };

  listType = '';

  rulesSlug = {
    aci_rules: 'aci-rules/',
    nan_std_rules: 'nan-std-rules/',
    restricted_words: 'restricted_words/',
    questions: '',
    manual_finding_groups: 'mfg/',
    gaar: 'gaar-rules/',
  };
  $subscriptions: Subscription[] = [];
  permission = permission;

  constructor(private sharedService: SharedService,
    private toastr: ToastService,
    private eventEmitterService: EventEmitterService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public router: Router,
    public userConfig: UserConfigurationService) {
      this.$subscriptions.push(
        this.eventEmitterService.refreshTableEvent.subscribe(res => {
          this.getRuleCodeFromParams()
          this.getDataSource(true);
        })
      )
    }

  ngOnChanges() {
    this.getRuleCodeFromParams();
    this.getDataSource(true);
  }

  getRuleCodeFromParams() {
    this.route.queryParams.subscribe(params => {
      if (params && params['rule']) {
        this.filters.search_term = params['rule'];
        this.searchTerm = params['rule'];
      } else {
        this.filters.search_term = '';
        this.searchTerm = '';
      }
    });
  }

  getDataSource(shouldGetRuleCount: boolean) {
    this.columnConfiguration = [];
    this.triggerRuleEngineOrClientCheckList();

    const statusWithAction: any = { name: 'Status', key: 'status', type: 'toggle', sortable: false, actions: [] }
    if (this.ruleName === this.ruleEngine.ACI_RULES && this.selectedRule === 0) {
      this.listType = this.ruleEngine.ACI_RULES;
      const columns = [
        { name: 'ACI Code', key: 'rule_code', type: TableColumnTypes.TEXT, sortable: true },
        { name: 'Description', key: 'description', type: TableColumnTypes.TEXT, sortable: true },
      ];
      if (!this.clientId) {
        columns.push({ name: 'Mapping', key: 'nan_std_rule_id', type: TableColumnTypes.MAPPING, sortable: false })
      }
      columns.push(statusWithAction)
      this.handleRuleDataSource(
        this.rulesSlug.aci_rules,
        columns,
        shouldGetRuleCount
      );
    } else if (this.ruleName === this.ruleEngine.NAN_STANDARD_RULES && this.selectedRule === 1) {
      this.listType = this.ruleEngine.NAN_STANDARD_RULES;
      const columns = [
        { name: 'NAN Standard Rule', key: 'code', type: TableColumnTypes.TEXT, sortable: true },
        { name: 'Description', key: 'description', type: TableColumnTypes.TEXT, sortable: true },
        { name: 'Type', key: 'is_basic_rule', type: TableColumnTypes.BOOLEAN, sortable: true, display: {validCase: 'Basic', invalidCase: 'Advance'} },
      ];
      if (!this.clientId) {
        columns.push({ name: 'Mapping', key: 'gaar_rules', type: TableColumnTypes.ARRAY, sortable: false})
        statusWithAction.actions.push(TableActions.EDIT);
        statusWithAction.isBasic = 'is_basic_rule';
      }
      columns.push(statusWithAction)
      this.handleRuleDataSource(
        this.rulesSlug.nan_std_rules,
        columns,
        shouldGetRuleCount
      );
    } else if (this.ruleName === this.ruleEngine.RESTRICTED_WORDS && this.selectedRule === 2) {
      this.listType = this.ruleEngine.RESTRICTED_WORDS;
      const columns = [
        { name: 'Restricted Word', key: 'word', type: TableColumnTypes.TEXT, sortable: true },
        { name: 'Category', key: this.clientId ? 'category' : 'restricted_words_category', type: this.clientId ? TableColumnTypes.TEXT : TableColumnTypes.OBJECT, sortable: true, childKey: 'category' },
        statusWithAction,
      ];
      if (!this.clientId) {
        statusWithAction.actions.push(TableActions.EDIT);
      }
      this.handleRuleDataSource(
        this.rulesSlug.restricted_words,
        columns,
        shouldGetRuleCount
      );
    } else if (this.ruleName === this.ruleEngine.QUESTIONS && this.selectedRule === 3) {
      this.listType = this.ruleEngine.QUESTIONS;
      this.filters.sort_field = 'question';
      const columns = [
        { name: 'Questions', key: 'question', type: TableColumnTypes.TEXT, sortable: true },
        { name: 'Status', key: 'status', type: 'toggle', sortable: false,  actions:[TableActions.EDIT, TableActions.DELETE] },
      ];
      this.handleRuleDataSource(
        this.rulesSlug.questions,
        columns,
        shouldGetRuleCount
      );
    } else if (this.ruleName === this.ruleEngine.MANUAL_FINDING_GEOUPS && this.selectedRule === 3) {
      this.listType = this.ruleEngine.MANUAL_FINDING_GEOUPS;
      const columns = [
        { name: 'MFG Code', key: 'mfg_code', type: TableColumnTypes.TEXT, sortable: true },
        { name: 'Description', key: 'description', type: TableColumnTypes.TEXT, sortable: true },
        { name: 'Status', key: 'status', type: 'toggle', sortable: false,  actions:[TableActions.EDIT, TableActions.DELETE] },
      ];
      this.handleRuleDataSource(
        this.rulesSlug.manual_finding_groups,
        columns,
        shouldGetRuleCount
      );
    } else if (this.ruleName === this.ruleEngine.GAAR && this.selectedRule === 4 && this.userConfig.checkPermissions(this.permission.rules[0])) {
      this.listType = this.ruleEngine.GAAR;
      const columns: any = [
        { name: 'GAAR Rule', key: 'rule_code', type: TableColumnTypes.TEXT, sortable: true },
        { name: 'Description', key: 'description', type: TableColumnTypes.TEXT, sortable: true }
      ];
      if(!this.clientId) {
        columns.push({ name: 'Mapping', key: 'nan_std_rule_id', type: TableColumnTypes.MAPPING, sortable: false },{ name: 'Status', key: 'status', type: TableColumnTypes.TOGGLE, sortable: false,  actions:[TableActions.EDIT] })
      } else {
        columns.push({ name: 'Status', key: 'status', type: TableColumnTypes.TOGGLE, sortable: false })
      }

      this.handleRuleDataSource(
        this.rulesSlug.gaar,
        columns,
        shouldGetRuleCount
      );
    }

  }

  handleRuleDataSource(
    ruleSlug: string,
    columns: any,
    shouldGetRuleCount: boolean,
  ) {
    this.fetchRules(ruleSlug, this.filters);
    if (shouldGetRuleCount) {
      this.fetchRulesStatus(ruleSlug, this.filters);
    }

    columns.forEach((column: any) => {
      this.addColumn(column.name, column.key, column.type, column.sortable, column.childKey, column.actions, column.display, column.isBasic);
    });
  }

  triggerRuleEngineOrClientCheckList() {
    if (this.clientId) {
      this.rulesSlug.aci_rules = `clients/aci-rules/${this.clientId}`;
      this.rulesSlug.nan_std_rules = `clients/nan-std-rules/${this.clientId}`;
      this.rulesSlug.restricted_words = `clients/restricted-words/${this.clientId}`;
      this.rulesSlug.questions = `clients/questions/${this.clientId}`;
      this.rulesSlug.gaar = `clients/gaar-rules/${this.clientId}`;
    }
  }

  addColumn(caption: string, field: string, fieldType: string, isSortable: boolean, childKey = '', actions: string[] = [], display: any, isBasic: string) {
    this.columnConfiguration.push({
      caption: caption,
      field: field,
      fieldType: fieldType,
      isSortable: isSortable,
      childKey: childKey,
      actions: actions,
      display: display,
      isBasic: isBasic
    });
  }

  fetchRules(slug: string, filters: any) {
    filters.count = false;
    this.sharedService.fetchAllRulesForRuleEngine(slug, filters).subscribe(
      (response) => {
        this.totalRecords = response.total_items;
        this.dataSource = response.data;
        this.getLoadedRules.emit(this.dataSource);
      },
      (error: any) => {
        this.totalRecords = 0;
        this.dataSource = [];
      },
    );
  }

  fetchRulesStatus(slug: string, filters: any) {
    filters.count = true;
    this.sharedService.fetchAllRulesForRuleEngine(slug, filters).subscribe((response) => {
      this.statusCount = response;
    });
  }

  getRulesOnChange(value: any) {
    this.filters.sort_field = '';
    this.filters.page = 1;
    this.selectedChecklistType.emit(value);
  }

  getStatusOnChange(value: any) {
    this.filters.status = value;
    this.resetPagination();
    this.getDataSource(false);
  }

  actionPerformedOnTable(data: any) {
    if (data.actionPerformed === TableActions.SORT) {
      this.sortData(data);
    }
    if (data.actionPerformed === TableActions.PAGINATE) {
      this.onPageChange(data);
    }
    if (data.actionPerformed === TableActions.TOGGLE) {
      this.toggleRuleStatus(data);
    }
    if (data.actionPerformed === TableActions.EDIT) {
      this.addRuleModal(this.ruleName, data)
    }
    if (data.actionPerformed === TableActions.DELETE && !this.clientId) {
      this.DeleteRuleModal(data.id)
    }
  }

  toggleRuleStatus(data: any) {
    const param: FormData = new FormData();
    if (this.clientId) {
      param.append('client_id', this.clientId);
      param.append('status', data.value);
    } else {
      if (this.ruleName === this.ruleEngine.GAAR) {
        param.append('gaar', JSON.stringify({ status: data.value }));
      } else {
        param.append(this.ruleName === this.ruleEngine.MANUAL_FINDING_GEOUPS ? 'mfg' : 'rule', JSON.stringify({ status: data.value }));
      }
    }
    this.triggerRuleEngineOrClientCheckList();
    const slug = this.setRuleSlug();
    this.updateRuleStatus(slug, param, data.id);
  }

  setRuleSlug() {
    const ruleMapping: { [key: number]: string } = {
      0: this.rulesSlug.aci_rules,
      1: this.rulesSlug.nan_std_rules,
      2: this.rulesSlug.restricted_words,
      3: this.ruleName === this.ruleEngine.MANUAL_FINDING_GEOUPS ? this.rulesSlug.manual_finding_groups : this.rulesSlug.questions,
      4: this.rulesSlug.gaar
    };

    return ruleMapping[this.selectedRule] || '';
  }

  updateRuleStatus(slug: string, data: any, ruleId: string) {
    this.sharedService.ruleEngineUpdateStatues(slug, data, ruleId).subscribe((response) => {
      const statusMapping: { [key: number]: string } = {
        0: RuleEngineListSingular.ACI_RULE,
        1: RuleEngineListSingular.NAN_STANDARD_RULE,
        2: RuleEngineListSingular.RESTRICTED_WORD,
        3: this.ruleName === this.ruleEngine.MANUAL_FINDING_GEOUPS ? RuleEngineListSingular.MANUAL_FINDING_GEOUPS : RuleEngineListSingular.QUESTION,
        4: RuleEngineListSingular.GAAR
      };
      this.toastr.success(`${statusMapping[this.selectedRule]} ${response.status && response.status !== 'False' ? message.activated : message.deactivated}`);
      this.fetchRulesStatus(slug, this.filters);
      this.getDataSource(false);
    });
  }

  sortData(event: any) {
    this.filters.search_term = this.searchTerm;
    this.filters.sort_field = event.active === 'restricted_words_category' ? 'category' : event.active;
    this.filters.descending = event.direction === 'desc' ? true : false;
    if (event.direction === '') {
      this.filters.sort_field = 'created_on';
      this.filters.descending = true;
    }
    this.resetPagination();
    this.getDataSource(false);
  }

  onPageChange(event: any): void {
    this.filters.search_term = this.searchTerm;
    this.filters.page = event.pageIndex + 1;
    this.filters.page_size = event.pageSize;
    this.getDataSource(false);
  }

  searchRule(): void {
    this.filters.search_term = this.searchTerm?.toLowerCase().trim();
    this.resetPagination();
    this.getDataSource(false);
  }

  resetPagination() {
    this.filters.page = 1;
    this.pageInfo = {
      pageIndex: 0,
      pageSize: config.listPageSize,
      maxPagesToShow: config.maxPagesToShow,
      pageSizeOptions: config.pageSizeOptions,
    };
  }


  addRuleModal(ruleName: string, data?: any) {
    let dialogComponent: any;

    switch (ruleName) {
      case this.ruleEngine.NAN_STANDARD_RULES:
        dialogComponent = CreateNanStdRulesComponent;
        break;
      case this.ruleEngine.RESTRICTED_WORDS:
        dialogComponent = CreateRestrictedWordsComponent;
        break;
      case this.ruleEngine.QUESTIONS:
        dialogComponent = CreateQuestionComponent;
        break;
      case this.ruleEngine.MANUAL_FINDING_GEOUPS:
        dialogComponent = CreateManualFindingGroupComponent;
        break;
      case this.ruleEngine.GAAR:
        dialogComponent = CreateGaarRulesComponent;
        break;
      default:
        return;
    }

    const dialogRef = this.dialog.open(dialogComponent, {
      panelClass: ['add-client-modal'],
      maxWidth: '100%',
      disableClose: true,
      autoFocus: false,
      data: {
        clientId: this.clientId,
        data
      }
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.filters.sort_field = 'created_on';
        this.getDataSource(true);
      }
    });
  }

  DeleteRuleModal(ruleId: string) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      panelClass: ['add-client-modal'],
      maxWidth: '100%',
      disableClose: true,
      autoFocus: false,
      data: {
        heading: 'Delete Manual Finding Group',
        contentData: message.DeleteManualFindingGroup,
        id: ruleId
      }
    });

    dialogRef.afterClosed().subscribe(() => {
        this.filters.sort_field = 'created_on';
        this.getDataSource(true);
    });
  }

  navigateToBasicExpressionBuilder(): void {
    this.router.navigateByUrl(`rules/expression-builder`);
  }

  resetSearchFilter() {
    this.filters.search_term = '';
    this.searchTerm = '';
    this.router.navigate([], { queryParams: {tab: this.selectedRule} });
    this.getDataSource(true);
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
