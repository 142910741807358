import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/core/services/toast.service';
import { SharedService } from '../../shared.service';
import { message } from '../../constants/alerts_messages';
import { generalErrorMessage } from '../../errors/error-messages';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  heading: string;
  action: string;
  message: string;
  btn = '';
  isHtml = false;

  constructor(
    private router: Router,
    private toastr: ToastService,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.heading = data.heading;
    this.action = data.action;
    this.message = data.contentData;
    this.isHtml = data?.isHtml;
    this.btn = data?.btn;
  }

  deleteOrderMessage(): void {
    this.sharedService.deleteOrderMessages(this.data.messageId).subscribe(
      () => {
        this.toastr.success('Message has been deleted.');
        this.dialogRef.close();
      },
      (error) => {
        this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error?.error?.detail :  generalErrorMessage);
      },
    );
  }

  deleteFinding(): void {
    this.sharedService.deleteManualFinding(this.data.findingId).subscribe(
      () => {
        this.toastr.success('Finding has been deleted.');
        this.dialogRef.close(true);
      },
      (error) => {
        this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error?.error?.detail :  generalErrorMessage);
      },
    );
  }

  deleteManualFindingGroup(): void {
    this.sharedService.deleteManualFindingGroup(this.data.id).subscribe(
      () => {
        this.toastr.success(message.DeleteMfgSuccessMessage);
        this.dialogRef.close();
      },
      (error) => {
        this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error?.error?.detail :  generalErrorMessage);
      },
    );
  }

  Done(): void {
    if (this.heading === 'Delete Message') {
      this.deleteOrderMessage();
    } else if(this.heading === 'Delete Finding') {
      this.deleteFinding();
    } else if (this.heading === 'Delete Manual Finding Group') {
      this.deleteManualFindingGroup();
    } else {
      this.dialogRef.close(true);
    }
  }
}
