export const requiredErrorMessage = 'Please fill out this field.'
export const noResultAvailable = 'No Results are Available'
export const generalErrorMessage = 'Something went wrong. Please try again.'
export const generalFirbaseErrorMessage = 'Something went wrong. Please try again or contact the administration.'

export const authErrorMessages: { [key: string]: string } = {
  "auth/id-token-expired": "Your session has expired. Please sign in again.",
  "auth/id-token-revoked": "Your session is no longer valid. Please sign in again.",
  "auth/insufficient-permission": "Insufficient permission, please contact the administration.",
  "auth/internal-error": "An unexpected error occurred. Please try again later or contact the administration.",
  "auth/email-already-exists": "This email is already associated with an existing account. Please use a different email.",
  "auth/invalid-email": "The email address you entered is not valid. Please check the format and try again.",
  "auth/invalid-email-verified": "Invalid email or password. Please try again.",
  "auth/user-not-found": "Invalid email or password. Please try again.",
  "auth/email-not-found": "Invalid email or password. Please try again.",
  "auth/too-many-requests": "Your account has been temporarily locked due to exceeding the maximum number of requests. Please try again later.",
  "auth/wrong-password": "Invalid email or password. Please try again.",
  "auth/invalid-password": "Password is invalid. it must be at least 6 characters long.",
  "auth/weak-password": "The password is weak. Please use a stronger password.",
  "auth/operation-not-allowed": "The operation is not allowed. You may need to enable some configurations. Please contact the administration.",
  "auth/invalid-credential": "Authentication failed due to an invalid credential. Please contact the administration if you need assistance.",
  "auth/user-disabled": "Please connect with administrator to activate your account.",
  "auth/email-change-needs-verification": "To continue, please check your inbox for a verification email sent to your new email address. If you didn't receive it, you can request a new verification link.",
  "auth/email-already-in-use": "Some of the provided data is already associated with other account. Please verify and try again.",
  "auth/credential-already-in-use": "This credential is already associated with another account.",
  "auth/account-exists-with-different-credential": "An account with this email already exists with a different credential.",
  "auth/network-request-failed": "A network request has failed.",
  "auth/popup-blocked": "Popup blocked by the browser.",
  "auth/popup-closed-by-user": "Popup closed by user.",
  "auth/provider-already-linked": "This provider is already linked to your account.",

  "auth/timeout": "A timeout has occurred. Please try again.",
  "auth/redirect-cancelled-by-user": "Redirect cancelled by user.",
  "auth/redirect-operation-pending": "Redirect operation is pending.",
  "auth/user-token-expired": "Session has expired. Please sign in again.",
  "auth/rejected-credential": "The provided credentials could not be verified. Please try signing in again.",
  "auth/unverified-email": "Unverified email address. Please verify your email address or contact the administration.",
  "auth/user-cancelled": "User cancelled authentication.",
  "auth/user-mismatch": "Please ensure that you are using the correct credentials for your account. If you need to switch accounts, please log out and try signing in again.",
  "auth/user-signed-out": "User has signed out.",
  "auth/invalid-phone-number": "PhoneNumber is invalid. Please check the format and try again.",
  "auth/invalid-photo-url": "Please ensure the photo URL is a valid link to an image.",
  "auth/invalid-argument": "The provided information is invalid. Please verify and try again.",
  "auth/phone-number-already-exists": "Some of the provided information is already in use by an existing user.",
  "auth/requires-recent-login": "Please login again, as your last login was too long ago.",
  "auth/cancelled-popup-request": "If you intended to sign in, please try again. Make sure to complete the process in the popup window.",
  "auth/invalid-claims": "The data provided does not meet the required format. Please review and try again.",
  "auth/invalid-creation-time": "The date provided is not valid. Please ensure the correct format and try again.",
  "auth/invalid-disabled-field": "Invalid value provided. Please check the information and try again.",
  "auth/invalid-display-name": "The display name provided is invalid. Please ensure it is not empty and try again.",
  "auth/claims-too-large": "The information provided is too large to process. Please try reducing the data and try again."
};

