export enum OrderStatuses {
  PROCESSING = 'Processing',
  COMPLETED = 'Completed',
  PENDING_REVIEW = 'Pending Review',
  PENDING_REVIEWER = 'Pending Reviewer',
  PENDING_ESCALATED_REVIEW = 'Pending Escalated Review',
  REVISION_RECEIVED = 'Revision Received',
  REVIEW_IN_PROGRESS = 'Review in Progress',
  CONDITION_SENT_TO_APPRAISER = 'Condition Sent to Appraiser',
  ESCALTED_REVIEW_IN_PROGRESS = 'Escalated Review in Progress',
  ESCALATED_REVIEW_COMPLETE = 'Escalated Review Complete',
  REVISION_REQUESTED = 'Revision Requested',
  COMPLETED_NEW_VERSION_RECIEVED = 'Completed - New Version Received',
  ARCHIVED = 'Archived',
  CLOSED = 'Closed',
  ON_HOLD = 'On Hold',
  CONDITION_SENT_TO_VENDOR = 'Condition Sent to Vendor',
  PENDING = 'Pending',
  IN_PROGRESS = 'In Progress',
  READY_FOR_APPRAISER_REVIEW ='Ready for Appraisers Review'
}

export enum OrderConversionStatuses {
  SUCCEEDED = 'Succeeded',
  PROCESSING = 'Processing',
  FAILED = 'Failed',
  WAITING_FOR_UPLOAD = 'WaitingForUpload'
}

export enum FieldType {
  Reviewer = 'reviewer',
  Status = 'status',
  Client = 'client',
  SubStatus = 'subStatus',
  Tier = 'tier',
}

export enum USPSVerification {
  VERIFIED = 'VALID_ADDRESS',
  NOT_VERIFIED = 'INVALID_ADDRESS',
}

export enum ModalTitle {
  REVIEWERS_LIST = 'Reviewers List',
  ESCALATE_TO = 'Escalate To'
}

export enum FindingStatues {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  NOT_APPLICABLE = 'Not Applicable',
  RESOLVED = 'resolved',
  UN_RESOLVED = 'unResolved',
  COMPLETED = 'completed',
  EDIT = 'edit',
  ALL = 'All',
}

export enum RevisionHistoryActions {
  STATUS_UPDATED = 'STATUS_UPDATED',
  ORDER_CREATED = 'ORDER_CREATED',
  ASSIGN_MYSELF = 'ASSIGN_MYSELF',
  NEW_REVIEWER_ASSIGNMENT = 'NEW_REVIEWER_ASSIGNMENT',
  ORDER_ESCALATION = 'ORDER_ESCALATION',
  NEW_REVISION_CREATED = 'NEW_REVISION_CREATED'
}

export enum LoanTypes {
  TWOTHK = '203k',
  CONVENTIONAL = 'Conventional',
  FHA = 'FHA',
  FHLMC = 'FHLMC',
  FORECLOSURE = 'Foreclosure / REO',
  NEWCONSTRUCTION = 'NewConstruction',
  OTHER = 'Other',
  REVERSE_MORTGAGE = 'ReverseMortgage',
  USDA = 'USDA',
  VA = 'VA'
}

export enum OrderPurposeTypes {
  REFINANCE = 'Refinance',
  PURCHASE = 'Purchase',
  ASCERTAIN_MARKET_VALUE = 'Ascertain Market Value',
  OTHER = 'Other'
}

export enum AppraisalNumbers {
  APPRAISAL_ONE = 'Appraisal_1',
  APPRAISAL_TWO = 'Appraisal_2',
  APPRAISAL_THREE = 'Appraisal_3'
}
