import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { ActivatedRoute } from '@angular/router';
import { noResultAvailable } from '../../errors/error-messages';

@Component({
  selector: 'app-tier-card',
  templateUrl: './tier-card.component.html',
  styleUrls: ['./tier-card.component.scss']
})
export class TierCardComponent {
  @Input() heading = '';
  @Input() data: any;
  @Input() filterBy: any[] = [];
  @Input() selectedIds: any[] = [];
  @Input() autoAdd = false;
  @Input() isAllDataSelected = false;
  @Input() unSelectIds: any[] = [];
  @Input() tierId = '';

  @Output() searchAttributeTerm = new EventEmitter<{ term: string, type: string}>();
  @Output() checkedIds = new EventEmitter<{ ids: string[], unselected: string[], all: boolean, type: string}>();
  @Output() sortAttributesData = new EventEmitter<{ order: string, sort: string, type: string}>();
  @Output() filterAttributesData = new EventEmitter<{ filter: string[], type: string}>();
  @Output() autoAddNewEvent = new EventEmitter<{ value: boolean, type: string}>();
  @Output() loadIncrementalData = new EventEmitter<string>();

  dataSource: any;
  searchTerm = '';
  sortedIn = 'desc';
  sortedOn = 'created_on';
  clickCount = 0;
  selectedFilters: string[] = [];
  isAllSelected = false;
  showFilter = false;
  isEdit = false;
  isPreview = false;
  autoAddNewControl = new FormControl(this.autoAdd);

  noDataMessage = noResultAvailable;

  constructor(private dialog: MatDialog, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      if (params['mode'] == 'isEdit') {
        this.isEdit = true;
      } else if(params['mode'] == 'isPreview') {
        this.isPreview = true;
        this.isEdit = true;
      }
    });
    this.autoAddNewControl.valueChanges.subscribe(value => {
      if(value !== null) {
        this.autoAddNewEvent.emit({value: value, type: this.heading});
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.dataSource = this.data;
    }
    if (changes['autoAdd']) {
      this.autoAddNewControl.setValue(this.autoAdd);
    }
    if (changes['selectedIds'] && this.selectedIds.length ) {
      this.updateSelectAllState();
    }
    if (changes['isAllDataSelected']) {
      this.isAllSelected = this.isAllDataSelected;
    }
    if (changes['tierId']) {
      this.searchTerm = '';
    }
  }

  scroll = (event: any): void => {
    const threshold = this.isPreview ? 12 : 0;
    if ((event.target.offsetHeight + event.target.scrollTop + threshold >= event.target.scrollHeight) && (this.dataSource?.length % 25 === 0)) {
      this.loadIncrementalData.emit(this.heading);
    }
  };

  search(): void {
    this.searchAttributeTerm.emit({term: this.searchTerm, type: this.heading});
  }

  toggleSelection(item: any): void {
    if (!this.isAllSelected) {
      const index = this.selectedIds.indexOf(item.id);
      if (index > -1) {
        this.selectedIds.splice(index, 1);
      } else {
        this.selectedIds.push(item.id);
      }
    }
    else {
      const index = this.unSelectIds.indexOf(item.id);
      if (index > -1) {
        this.unSelectIds.splice(index, 1);
      } else {
        this.unSelectIds.push(item.id);
      }
    }
    this.updateSelectAllState();
    this.checkedIds.emit({ ids: this.selectedIds, unselected: this.unSelectIds, all: this.isAllSelected, type: this.heading});
  }

  toggleSelectAll(): void {
    this.isAllSelected = !this.isAllSelected;
    if (this.isAllSelected) {
      this.selectedIds = this.dataSource.map((item: any) => item.id);
    } else {
      this.selectedIds = [];
      this.unSelectIds = [];
    }
    this.checkedIds.emit({ ids: this.selectedIds, unselected: this.unSelectIds, all: this.isAllSelected, type: this.heading});
  }

  updateSelectAllState(): void {
    this.isAllSelected = (this.selectedIds.length === this.dataSource.length && this.searchTerm === '') || this.unSelectIds.length > 0;
  }

  toggleFiltersSelection(item: any): void {
    const index = this.selectedFilters.indexOf(item.key);
    if (index > -1) {
      this.selectedFilters.splice(index, 1);
    } else {
      this.selectedFilters.push(item.key);
    }
  }

  applyFilters(): void {
    this.searchTerm = '';
    if (!this.isEdit) {
      this.selectedIds = [];
      this.isAllSelected = false;
    }
    this.filterAttributesData.emit({ filter: this.selectedFilters, type: this.heading});
  }

  toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }

  sortData() {
    this.clickCount++;
    if (this.clickCount % 3 === 1) {
      this.sortedOn = 'name';
      this.sortedIn = 'desc';
    } else if (this.clickCount % 3 === 2) {
      this.sortedOn = 'name';
      this.sortedIn = 'asc';
    } else {
      this.sortedOn = 'created_on';
      this.sortedIn = 'desc';
    }
    this.sortAttributesData.emit({order: this.sortedIn, sort: this.sortedOn, type: this.heading});
  }


  getNANStdDetail(item: any) {
    this.dialog.open(ConfirmationModalComponent, {
      panelClass: ['order-complete-modal'],
      maxWidth: '100%',
      disableClose: true,
      data: {
        heading: `NAN Standard Rule Details`,
        contentData: `<h3>Rule Code: ${item.name}</h3><div class="description"><h4>Findings Text:</h4><span>${item.description}</span></div>`,
        isHtml:true
      },
    });
  }
}
