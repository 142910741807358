<div class="table-wrap">
  <table mat-table [dataSource]="_dataSource" class="rules__table" matSort (matSortChange)="sortData($event)" aria-label="list">
    <ng-container *ngIf="columns && columns.length > 0">
      <ng-container *ngFor="let col of columns" [matColumnDef]="col.field">
        <ng-container *ngIf="col.isSortable">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{col.caption}} <span *ngIf="filters.sort_by !== col.field || filters.sort_order === ''" class="default-sort"></span>
          </th>
        </ng-container>
        <ng-container *ngIf="!col.isSortable">
          <th mat-header-cell *matHeaderCellDef>
            {{col.caption}}
          </th>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.LINK">
          <td mat-cell *matCellDef="let element">
            <span class="link" *ngIf="getRedirectUrl(element) | async as url" [routerLink]="'orders/order-review/'+element.id" [queryParams]="url.queryParams">{{element[col.field]}}</span>
          </td>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.SMARTLINK && userConfig.checkPermissions(permission.VIEW_CLIENTS)">
          <td mat-cell *matCellDef="let element">
            <app-client-smart-link *ngIf="userConfig.checkPermissions(permission.VIEW_CLIENTS) && element" [clientId]="element.client.id" [clientName]="element.client.company_name" [hoverView]="true"></app-client-smart-link>
          </td>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.TEXT">
          <td mat-cell *matCellDef="let element">
            {{element[col.field]}}
          </td>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.ARRAY">
          <td mat-cell *matCellDef="let element">
            <a class="order-click" [routerLink]="['/rules']" [queryParams]="{tab: 5, ruleId: element?.id, ruleName: element?.code}" *ngIf="element?.aci_rules.length > 0 || element?.gaar_rules.length > 0">{{element?.gaar_rules.length + element?.aci_rules.length}}</a>
          </td>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.MAPPING">
          <td mat-cell *matCellDef="let element">
            <a class="order-click" *ngIf="element?.nan_std_rule_id" [routerLink]="['/rules']" [queryParams]="{tab: 5, ruleId: element?.nan_std_rule_id, ruleName: element?.nan_std_rule?.code}">{{element?.nan_std_rule?.code}}</a>
          </td>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.BOOLEAN">
          <td mat-cell *matCellDef="let element">
            {{element[col.field] ? col.display?.validCase : col.display?.invalidCase}}
          </td>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.TIMER">
          <td mat-cell *matCellDef="let element" [realTimer]="element?.hours" [format]="'DHMS'" class="date">
            {{element[col.field]}}
          </td>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.DATE_TIME">
          <td mat-cell *matCellDef="let element">
            {{element[col.field] | localDate:'medium'}}
          </td>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.DATE">
          <td mat-cell *matCellDef="let element">
            {{element[col.field] | date:'mediumDate'}}
          </td>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.OBJECT">
          <td mat-cell *matCellDef="let element">
            {{element[col.field][col.childKey]}}
          </td>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.CHIP">
          <td mat-cell *matCellDef="let element">
            <app-order-status [orderStatus]="element[col.field][col.childKey]"></app-order-status>
          </td>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.CIRCLE">
          <td mat-cell *matCellDef="let element">
            <app-order-status [orderConversionStatus]="element[col.field]"></app-order-status>
          </td>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.CV">
          <td mat-cell *matCellDef="let element">
            <div class="item-wrapper">
            <ng-container *ngFor="let cvWord of element[col.field]; let i = index">
                <ng-container *ngIf="i < 7">
                  <app-order-status [textToDisplay]="cvWord"></app-order-status>
                </ng-container>
                <ng-container *ngIf="i === 7 && element[col.field].length > 7">
                  <app-order-status [cvRemainingWordsCount]="element[col.field].length - 7"></app-order-status>
                </ng-container>
            </ng-container>
          </div>
          </td>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.ICON">
          <td mat-cell *matCellDef="let element">
            <app-order-status [orderManager]="element[col.field]"></app-order-status>
          </td>
        </ng-container>
        <ng-container *ngIf="col.fieldType === columnType.TOGGLE">
          <td mat-cell *matCellDef="let element">
            <div class="status-wrap">
              <label class="switch">
                <input type="checkbox" [checked]="element[col.field]" (click)="$event.stopPropagation()" (change)="statusOnChange($event, element)">
                <span class="slider round"></span>
              </label>
              <ng-container *ngIf="col.actions && col.actions.length > 0">
                <mat-icon class="rules__table__table-action" [matMenuTriggerFor]="actionMenu">more_vert</mat-icon>
                <mat-menu color="primary" #actionMenu="matMenu" class="action-menu">
                  <ng-container *ngIf="col?.isBasic">
                    <ng-container *ngFor="let item of col.actions">
                      <button (click)="element[col.isBasic!] ? redirectToBasicNSRule(element.id) : actionMenuClick(element, item)" mat-menu-item>{{item}}</button>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!col?.isBasic">
                    <ng-container *ngFor="let item of col.actions">
                      <button (click)="actionMenuClick(element, item)" mat-menu-item>{{item}}</button>
                    </ng-container>
                  </ng-container>
                </mat-menu>
              </ng-container>
            </div>
          </td>
        </ng-container>


        <!-- actions Column -->
        <ng-container *ngIf="col.fieldType === columnType.ACTION">
          <td mat-cell *matCellDef="let element">
            <mat-icon class="rules__table__table-action" [matMenuTriggerFor]="actionMenu">more_vert</mat-icon>
            <mat-menu color="primary" #actionMenu="matMenu" class="action-menu">
              <ng-container *ngFor="let item of col.actions">
                <button (click)="actionMenuClick(element, item)" mat-menu-item>{{item}}</button>
              </ng-container>
            </mat-menu>
          </td>
        </ng-container>

      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div *ngIf="_dataSource?.length === 0" class="no-records">{{noResultAvailableTxt}}</div>
<app-custom-paginator *ngIf="pageInfo" [length]="totalRecords" [pageInfo]="pageInfo" [listType]="listType"
  (pageChange)="onPageChange($event)"></app-custom-paginator>
